@import 'aos/dist/aos.css';

html,
body,
#root {
  height: 100%;
  background-color: black;
}

.text-stroke {
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: black;
}
