.herro-text {
    background-image: linear-gradient(90deg, #fff, #777);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.benefit-title {
    background-image: linear-gradient(90deg, #af4261, #f3ec78);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
